if (typeof require !== "undefined") {
	var React = require("react");
	var moment = require("moment-timezone");
}

/*
 * Upcoming Training Classes
 * Static version from Mockup
 */
class FilterableClassTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = { filterCourse: "", data: [] };
		this.handleFilterChange = this.handleFilterChange.bind(this);
	}
	handleFilterChange(filter) {
		this.setState({ filterCourse: filter.course});
	}
	render() {
		var courses;

		courses = this.props.classes.map(function (c) {
			return c.course.title;
		}).sort();

		courses = Array.from(new Set(courses));

		return (
			<div className="filterableClassTable">
				<FilterBar
					courses={courses}
					filterCourse={this.state.filterCourse}
					onFilterChange={this.handleFilterChange}/>
				<ClassTable classes={this.props.classes} filterCourse={this.state.filterCourse} />
			</div>
		);
	}
}

class FilterBar extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange() {
		var select = this.selectElement;
		this.props.onFilterChange({
			course: select.options[select.selectedIndex].value
		});
	}
	render() {
		var optionNodes = this.props.courses.map(function (course) {
			return (
				<option key={course} value={course}>{course}</option>
			);
		});
		return (
			<form className="filterBar">
				<div>
					<label>Filter by Course</label>
					<select
						onChange={this.handleChange}
						ref={(select) => { this.selectElement = select; }}>
						<option value="">All Courses</option>
						{optionNodes}
					</select>
				</div>
			</form>
		);
	}
}

class ClassTable extends React.Component {
	render() {
		var rows, filterCourse, filteredClasses;

		filterCourse = this.props.filterCourse;

		if (filterCourse !== "") {
			filteredClasses = this.props.classes.filter(c => filterCourse === c.course.title);
		} else {
			filteredClasses = this.props.classes;
		}

		rows = filteredClasses.map(function (c) {
			return (
				<ClassRow data={c} key={c.id} />
			);
		});

		if (rows.length < 1) {
			rows = [(
				<div key="93247040-6d73-4a0a-a735-67a547797a80">
					<p>
						Sorry, there are no open, public classes at this time. More will be scheduled. Please check back later.
					</p>
				</div>
			)];
		}

		return (
			<div className="table-wrapper">
				<div className="table-row table-header">
					<div className="table-column date">Date</div>
					<div className="table-column name">Class</div>
					<div className="table-column location">Location</div>
					<div className="table-column action"></div>
				</div>
				{rows}
			</div>
		);
	}
}

class ClassRow extends React.Component {
	render() {
		var startDate, displayDate;

		startDate = moment(this.props.data.start);
		displayDate = startDate.tz(this.props.data.timeZone).format("MMM D");

		return (
			<div className="table-row">
				<div className="table-column date">
					<span>{displayDate}</span>
				</div>
				<div className="table-column name">
					<CourseInfo course={this.props.data.course} />
				</div>
				<div className="table-column location qc-widget-geolearning-upcoming-classes-location">
					<ClassInfo
						location={this.props.data.location}
						description={this.props.data.description} />
				</div>
				<div className="table-column action">
					<ClassSignUpButton
						inPerson={this.props.data.inPerson}
						url={this.props.data.url} />
				</div>
			</div>
		);
	}
}

class CourseInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = { collapsed: true };
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick() {
		this.setState({ collapsed: !this.state.collapsed });
	}
	render() {
		var dlClassName;

		dlClassName = "courseInfo";

		if (this.state.collapsed) {
			dlClassName += " collapsed";
		} else {
			dlClassName += " expanded";
		}

		return (
			<dl className={dlClassName} onClick={this.handleClick}>
				<dt>
					{this.props.course.title}
				</dt>
				<dd>
					<p>
						{this.props.course.description}
					</p>
				</dd>
			</dl>
		);
	}
}

class ClassInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = { collapsed: true };
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick() {
		this.setState({ collapsed: !this.state.collapsed });
	}
	render() {
		var dlClassName;

		dlClassName = "classInfo";

		if (this.state.collapsed) {
			dlClassName += " collapsed";
		} else {
			dlClassName += " expanded";
		}

		return (
			<dl className={dlClassName} onClick={this.handleClick}>
				<dt>
					{this.props.location}
				</dt>
				<dd>
					<p>
						{this.props.description}
					</p>
				</dd>
			</dl>
		);
	}
}

class ClassSignUpButton extends React.Component {
	render() {
		var classType, classTypeClassName;

		if (this.props.inPerson) {
			classType = "In-Person";
			classTypeClassName = "classSignUpButton classSignUpButtonInPerson";
		} else {
			classType = "Online";
			classTypeClassName = "classSignUpButton classSignUpButtonOnline";
		}

		return (
			<div className={classTypeClassName}>
				<a href={this.props.url} target="_blank" rel="noopener noreferrer">
					Sign Up</a>
				<span>{classType}</span>
			</div>
		);
	}
}

if (typeof module !== "undefined") {
	module.exports = FilterableClassTable;
}
